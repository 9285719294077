import avatar from "../../assets/user.jpg";
import { getToday } from "utils";
import { Navigate, Outlet } from "react-router-dom";
import { Sidebar } from "components/shared-components/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Account = () => {
  const [userData, setUserData]: any = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/me`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        const data = await res.json();
        if(!data.name) throw new Error();
        setUserData(data);
        if (location.pathname === "/account") navigate("points");
        return;
      } catch (err) {
        navigate("/auth/sign-in");
        return;
      }
    })();
  }, []);

  return (
    <div className="account__wrap flex items-stretch min-h-screen bg-[#EB6020] max-[640px]:p-3 max-[400px]:p-2 p-10 ">
      <div className="account__wrap_inner w-full flex flex-row max-[640px]:flex-wrap gap-7 max-[640px]:gap-2 items-stretch">
        <Sidebar className="sidebar shadow-lg bg-white/[1] max-[640px]:w-full max-[1020px]:w-4/12 max-[1620px]:w-3/12 w-2/12 p-3 h-full max-[640px]:h-auto flex flex-col justify-between" />
        <div className="aside shadow-lg bg-white/[.9] max-[1620px]:w-9/12 w-10/12 max-[640px]:w-full p-3">
          <div className="aside__top w-full flex justify-between active:opacity-[.9] pb-4 max-[640px]:pb-2 border-b border-[#cfcfcf]">
            <div className="flex items-center max-[640px]:mr-2">
              <h2 className="pl-3 text-[#555] font-semibold text-xl max-[920px]:text-md max-[640px]:text-xs cursor-pointer select-none">
                {getToday()}
              </h2>{" "}
            </div>
            { userData && <div className="user__info flex items-center bg-white p-2 px-4 rounded-2xl">
              <span className="pr-3 text-[#EB6020] max-[640px]:text-sm max-[640px]:text-xs font-semibold text-lg cursor-pointer select-none">
                {userData.name} {userData.lastname} 
              </span>
              <img
                src={avatar}
                className="select-none"
                width={40}
                height={40}
                alt="user-logo"
              />
            </div> }
          </div>
          <div className="aside__content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
