import { AccountCircle, AttachMoney, ExitToApp, LocalShipping, Send } from "@mui/icons-material";
import { Box, Button, List, ListItem, ListItemIcon, Modal } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import { logout } from "utils";
interface SidebarProps {
  className: string,
}

export const Sidebar = ({ className } : SidebarProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleModal = () => {
    setOpen(true);
  };


  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #eee",
    boxShadow: 24,
    p: 4,
  };
  const menuItems = [
    {
      icon: <AccountCircle fontSize="small" />,
      text: "Адреса для доставки",
      onClick: () => navigate("points"),
    },
    {
      icon: <Send fontSize="small" />,
      text: "Отправить запрос",
      onClick: () => navigate("calculate"),
    },
    {
      icon: <LocalShipping fontSize="small" />,
      text: "Тарифы на перевозки",
      onClick: () => navigate("pricing"),
    },
    {
      icon: <ExitToApp fontSize="small" />,
      text: "Выйти",
      onClick: handleModal,
    },
  ];


  return (
    <div className={className}>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <h3 className="text-[#2D2D2D] font-semibold text-[18px] sm:text-[18px] text-center mb-3">
            Вы уверены что хотите выйти?
          </h3>
          <div className="w-full flex justify-center">
            <Button
              variant="outlined"
              onClick={logout}
              color="error"
              style={{ marginRight: "10px" }}
            >
              Да
            </Button>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Вернуться назад
            </Button>
          </div>
        </Box>
      </Modal>
      <div className="mb-5">
        <div className="flex justify-center sm:justify-start p-4 max-[360px]:p-2 mb-2">
          <img src={logo} width={150} alt="logo" />
        </div>
        <h2 className="text-[#2D2D2D] font-bold text-[20px] max-[1140px]:text-[20px] text-[25px] text-center sm:text-left pl-4 mb-2">
          Личный кабинет
        </h2>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              button
              onClick={item.onClick}
              key={index}
              // disabled={item?.disabled}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.text}
            </ListItem>
          ))}
        </List>
      </div>

      <div className="text-black/[.45] text-xs text-center mb-3">
        Аккаунт пользователя CosmoCourier <br /> ver. 1.0.0
      </div>
    </div>
  );
};
