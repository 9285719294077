import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
  Modal,
  Box,
  AlertColor,
  InputAdornment
} from "@mui/material";
import { useState, useEffect } from "react";
import countriesData from "./data.json";
import axios from "axios";

const Contacts = () => {
  const [countries, setCountries]: any = useState(countriesData);
  const [displaySnackBar, setDisplaySnackBar] = useState({
    display: false,
    type: "error",
    message: "",
  });
  const [displayModal, setDisplayModal] = useState(false);
  const [calculateResult, setCalculateResult]: any = useState({
    price_uzs: null,
    price_usd: null,
    delivery_time_days: null,
    delivery_members: [],
  });
  const [fromCountry, setFromCountry] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [toCountry, setToCountry] = useState("UZ");
  const [toCity, setToCity] = useState("");
  const [category, setCategory] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [trackingData, setTrackingData] = useState("");

  const handleCalculate = async (
    returnData = false,
    axios: any,
    setCalculateResult: any,
    setDisplaySnackBar: any,
    setDisplayModal: any,
    fromCountry: any,
    fromCity: any,
    toCountry: any,
    toCity: any,
    category: any,
    weight: any,
  ) => {
    if (
      !fromCountry ||
      !toCountry ||
      !category ||
      !weight ||
      !fromCity ||
      !toCity
    ) {
      return setDisplaySnackBar({
        display: true,
        message: "Заполните обязательные поля",
        type: "error",
      });
    }
    if (fromCountry === toCountry) {
      return setDisplaySnackBar({
        display: true,
        message: "Пункт получения и отправления не могут совпадать",
        type: "error",
      });
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/calculate-delivery`,
        {
          country_from: fromCountry,
          city_from: String(fromCity),
          country_to: toCountry,
          city_to: String(toCity),
          weight: +weight,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.errors || !res.data) {
        setDisplaySnackBar({
          display: true,
          message:
            res.data.errors[0]?.message ||
            "Ошибка при подсчете стоимости. Попробуйте позже",
          type: "error",
        });
        return new Error();
      }
  
      const data = res?.data?.filter((item: any) => item !== null);
  
      setCalculateResult({
        price_uzs: data?.reduce(
          (acc: number, currentItem: any) => acc + currentItem.delivery_price_uzs,
          0
        ),
        price_usd: data?.reduce(
          (acc: number, currentItem: any) => acc + currentItem.delivery_price_usd,
          0
        ),
        delivery_time_days: data?.reduce(
          (acc: number, currentItem: any) => acc + currentItem.delivery_time_days,
          0
        ),
        delivery_members: data?.reduce((acc: number[], currentItem: any) => {
          acc.push(currentItem.delivery_member);
          return acc;
        }, []),
      });
  
      if (returnData) {
        setDisplayModal(true);
        return;
      }
    } catch (err) {
      console.log(err);
      setDisplaySnackBar({
        display: true,
        message: "Ошибка при подсчете стоимости. Попробуйте позже",
        type: "error",
      });
      return new Error();
    }
  };
  

  const handleApply = async () => {
    if (
      !fromCountry ||
      !toCountry ||
      !category ||
      !weight ||
      !fromCity ||
      !toCity ||
      !name ||
      !phone
    ) {
      return setDisplaySnackBar({
        display: true,
        message: "Заполните обязательные поля",
        type: "error",
      });
    }
    if (fromCountry === toCountry) {
      return setDisplaySnackBar({
        display: true,
        message: "Страна получения и отправления не могут совпадать",
        type: "error",
      });
    }
    const calculateRes = await handleCalculate(
      false,
      axios,
      setCalculateResult,
      setDisplaySnackBar,
      setDisplayModal,
      fromCountry,
      fromCity,
      toCountry,
      toCity,
      category,
      weight
    );
    if (calculateRes instanceof Error) return;

    try {
      console.log(calculateResult)
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/new-request`,
        {
          from_country: fromCountry,
          from_city:
            fromCountry === "RUS"
              ? countries[0]?.cities?.find(
                  (item: any) => item.value === fromCity
                ).label
              : fromCity,
          to_country: toCountry,
          to_city:
            toCountry === "UZ"
              ? countries[1]?.cities?.find((item: any) => item.value === toCity)
                  ?.label
              : toCity,
          category,
          weight: +weight,
          length: +length,
          width: +width,
          height: +height,
          name,
          phone_number: "+998" + phone,
          preview_price_usd: calculateResult.price_usd,
          preview_price_uzs: calculateResult.price_uzs,
          preview_delivery_time: calculateResult.delivery_time_days,
          preview_delivery_members: calculateResult.delivery_members,
          tracking_code: trackingData === "" ? undefined : trackingData,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      setFromCountry("");
      setFromCity("");
      setToCity("");
      setCategory("");
      setWeight("");
      setLength("");
      setHeight("");
      setWidth("");
      setName("");
      setPhone("");

      setDisplaySnackBar({
        display: true,
        message: "Ваш запрос передан в обработку! Ожидайте ответа менеджера!",
        type: "success",
      });
      setCalculateResult({
        price_uzs: null,
        price_usd: null,
        delivery_time_days: null,
        delivery_members: [],
      });
      return;
    } catch (err: any) {
      if (err?.response?.data?.message) {
        return setDisplaySnackBar({
          display: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      }
      return setDisplaySnackBar({
        display: true,
        message: "Ошибка при отправке запроса. Попробуйте позже",
        type: "error",
      });
    }
  };

  const categories = [
    { value: "Одежда/Текстиль" },
    { value: "Электроника" },
    { value: "Обувь" },
    { value: "Жидкости" },
    { value: "Косметика" },
    { value: "Продукты питания" },
    { value: "Мебель" },
    { value: "Хозяйственные товары" },
    { value: "Другое" },
  ];

  useEffect(() => {
    if (fromCountry === "RUS") {
      (async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/delivery/cities-rus`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );
          const newState = countries.map((item: any) => {
            if (item.value === "RUS") {
              item.cities = [...res.data];
            }
            return item;
          });
          setCountries(newState);
        } catch (err) {
          return setDisplaySnackBar({
            display: true,
            message: "Произошла ошибка при загрузке городов",
            type: "error",
          });
        }
      })();
    }
  }, [fromCountry]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/delivery/cities-uzb`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        const newState = countries.map((item: any) => {
          if (item.value === "UZ") {
            item.cities = [...res.data];
          }
          return item;
        });
        setCountries(newState);
      } catch (err) {
        return setDisplaySnackBar({
          display: true,
          message: "Произошла ошибка при загрузке городов",
          type: "error",
        });
      }
    })();
  }, []);

  return (
    <div className="contact p-3">
      <Modal
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        open={displayModal}
        onClose={() => setDisplayModal(false)}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            outline: "none",
            padding: "15px",
            maxWidth: 400,
            width: "100%",
          }}
        >
          <h2 className="font-semibold text-lg mb-2">
            Расчет стоимости доставки Вашей посылки:
          </h2>
          <span>
            Код маршрута:{" "}
            <strong>
              {fromCity}-{toCity}
            </strong>
          </span>{" "}
          <br />
          <span>
            Вес посылки: <strong>{weight} кг</strong>
          </span>{" "}
          <br />
          <span>
            Срок доставки посылки:{" "}
            <strong>~{calculateResult.delivery_time_days} дней</strong>
          </span>{" "}
          <br />
          <span>
            Службы доставки:{" "}
            <strong>{calculateResult.delivery_members?.join(", ")}</strong>
          </span>
          <br />
          <span>
            Стоимость услуг:{" "}
            <strong className="block border-t pt-1 mt-2 text-green-800">
              {calculateResult.price_usd?.toFixed(2)} USD (
              {calculateResult?.price_uzs?.toLocaleString("ru-RU")} UZS)*
            </strong>
          </span>
          <span className="text-xs text-[#aaa] inline-block mt-2">
            * указанная сумма является предварительным расчетом стоимости услуг,
            и может отличаться от итоговой, в зависимости от требований клиента
            и деталей заказа.
          </span>
          <span className="text-xs text-[#aaa] inline-block mt-2">
            * оплата услуг CosmoCourier осуществляется в узбекских сумах. Все цены,
            указанные в долларах США(USD) пересчитываются в соответствии с
            курсом ЦБ РУз на день совершения оплаты.
          </span>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={displaySnackBar.display}
        onClose={() =>
          setDisplaySnackBar({ ...displaySnackBar, display: false })
        }
        autoHideDuration={3000}
      >
        <Alert
          onClose={() =>
            setDisplaySnackBar({ ...displaySnackBar, display: false })
          }
          severity={(displaySnackBar.type as AlertColor) || "error"}
        >
          {displaySnackBar.message}
        </Alert>
      </Snackbar>
      <h2 className="text-[#2D2D2D] font-semibold text-[22px] max-[400px]:text-sm text-center">
        Отправить запрос на расчет доставки
      </h2>
      <p className="w-[50%] max-[400px]:w-full text-center text-sm max-[400px]:text-xs mx-auto py-2 my-4 text-black/[.45] border-[#ccc] border-y">
        После ввода данных и отпраки запроса, Ваша заявка будет принята
        оператором, и мы свяжемся с Вами для подтверждения заказа
        <br />
        <strong>
          Поля, отмеченные символом <span className="text-[red]/[.7]">*</span>
          (звездочка) являются обязательными к заполнению
        </strong>
      </p>

      <div className="content">
        <div className="request-form">
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Страна отправления*"
                  value={fromCountry}
                  onChange={(e) => setFromCountry(e.target.value)}
                >
                  {countries.map((country: any) => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Город отправления*"
                  value={fromCity}
                  disabled={!fromCountry}
                  onChange={(e) => setFromCity(e.target.value)}
                >
                  {countries
                    .find((item: any) => item.value === fromCountry)
                    ?.cities.map((city: any) => (
                      <MenuItem key={city.value} value={city.value}>
                        {city.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Страна получения*"
                  placeholder="Страна получения*"
                  defaultValue={"UZ"}
                  value={toCountry}
                  onChange={(e) => setToCountry(e.target.value)}
                >
                  <MenuItem key={"UZ"} value={"UZ"}>
                    Узбекистан
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Город получения*"
                  value={toCity}
                  onChange={(e) => setToCity(e.target.value)}
                >
                  {countries
                    .find((item: any) => item.value === toCountry)
                    ?.cities.map((city: any) => (
                      <MenuItem key={city.value} value={city.value}>
                        {city.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Вид содержимого посылки*"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.value} value={category.value}>
                      {category.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Вес (кг)*"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Длина (см)"
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Ширина (см)"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Глубина (см)"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="string"
                  label="Имя"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Номер телефона"
                  value={phone}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+998 </InputAdornment>,
                  }}
                  onChange={(e) => {setPhone(e.target.value)}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="string"
                  label="Трекинг-ссылка(не обязательно)"
                  value={trackingData}
                  onChange={(e) => {setTrackingData(e.target.value)}}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    handleCalculate(
                      true,
                      axios,
                      setCalculateResult,
                      setDisplaySnackBar,
                      setDisplayModal,
                      fromCountry,
                      fromCity,
                      toCountry,
                      toCity,
                      category,
                      weight
                    )
                  }
                  disabled={false}
                >
                  Получить предварительный подсчет
                </Button>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                >
                  Отправить заявку
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
